import { TopBar } from '../components/NavBar';
import { Content } from '../components/Content';
import { HeaderInformations } from '../components/HeaderInformations';

export default function Index() {
  return (
    <>
      <TopBar />
      <HeaderInformations />
      <Content />
    </>
  );
}
