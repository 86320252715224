import styled from 'styled-components';
import { Button } from "@chakra-ui/react";

export const Container = styled.div`
  max-width: 750px;
  width: 100%;
  /* background: #f8f8f8; */
  /* max-height: calc(100vh - 1rem); */
  margin: 0 auto;
  border-radius: 1rem;
  p {
    font-weight: #808080;
  }
`;

export const Block = styled.div`

`;

export const ButtonStyled = styled(Button)`
  margin: 1.5rem auto 1.5rem auto;
`

export const FormBody = styled.form`
  padding: 1rem;
  input { 
    background: var(--background-color);
    margin-bottom: 1rem;
    border: none;
    width: 100%;
    transition: all 0.2s;
    font-size: var(--chakra-fontSizes-md);
    -webkit-padding-start: var(--chakra-space-4);
    padding-inline-start: var(--chakra-space-4);
    -webkit-padding-end: var(--chakra-space-4);
    padding-inline-end: var(--chakra-space-4);
    height: var(--chakra-sizes-10);
    border-radius: var(--chakra-radii-md);
    border: 1px solid;
    border-color: inherit;
    &:focus{
      box-shadow: 0 0 0 0;
      outline: 0;
      border: 2px solid orange;
    }
  }
`;

export const FormFooter = styled.div`
  width: 100%;
  text-align: center;
  button { 
    margin-top: 1rem;
    width: 100%;
    color: white;
    font-size: 20px;
    border-radius: 1rem;
    background: var(--primary-color);
    padding: 0.3rem;
    font-weight: 700;
    &:disabled{
      background: #8a8a8a7a;
      cursor: not-allowed;
    }
  }
`;


export const ButtonCep = styled.span`
    margin: 0 auto;
    width: 80%;
    color: white;
    font-size: 1rem;
    border-radius: 1rem;
    background: var(--primary-color);
    margin-bottom: 1rem;
    padding: 0.45em;
    font-weight: 700;
    text-align: center;
    cursor: pointer;
    &:active{
      background: #EF7F3E;
    }
`;