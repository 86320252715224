import { Container, 
         ContainerImg, 
         ContainerBody, 
         ContainerTitle, 
         ContainerDescription, 
         ContainerPrice } 
from "./style";

import { Text /*, Divider */} from "@chakra-ui/react"
import { formatPrice } from '../../../../utils/formatNumber';  


interface CardProductDetailProps{
  nome: string;
  imagem: string;
  id: number;
  descricao: string;
  valor: number;
}

export function CardProductDetail({nome, imagem, id, descricao, valor}: CardProductDetailProps){
  return(
    <Container key={id}>
      <ContainerImg>
        <img src={imagem} alt="" />
      </ContainerImg>
      <ContainerBody>
        <ContainerTitle>

        <Text fontSize="xl">
          <b>{nome}</b>
        </Text>

          </ContainerTitle>
        <ContainerDescription>

        {/* <Text fontSize="xs">
        {descricao}        
        </Text> */}
        <br></br>
        
        </ContainerDescription>

        <ContainerPrice>
          <Text fontSize="sm">
            <b>{formatPrice(valor)}</b>
          </Text>
        </ContainerPrice>
      </ContainerBody>

    </Container>  
  );

}