import styled from "styled-components";

export const Container = styled.div`
  background: var(--shadow-color);
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const FormLogin = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 2fr 1fr;
  width: 25rem;
  height: 37.5rem;
  margin: auto auto;
  background: var(--background-color);
  border-radius: 1rem;
  .cabecalho {
    grid-column: 1/1;
    grid-row: 1/2;
    /* background: blue; */
    img { 
      margin: 1.5rem auto;
      width: 30%;
      border-radius: 50%;
    }
    h5 {
      text-align: center;
      font-weight: bold;
    }
  }

  .form {
    grid-column: 1/1;
    grid-row: 2/3;
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    gap: 0.5rem;
    align-items: center;
    & > div {
      display: -ms-flexbox;
      width: 80%;
      justify-content: space-between;
      .icon {
        padding: 0.64rem 0.1rem;
        border-radius: 0.5rem 0 0 0.5rem;

        background: var(--shadow-color);
        color: white;
        min-width: 3rem;
        text-align: center;
      }

      input { 
        padding: 0.4rem 0.1rem;
        border-radius: 0 0.5rem 0.5rem 0;
        width: 80%;
        margin: 0.8rem auto;
        &:focus{
          outline: none;
        }
      }
      
    }

    a { 
      float: left;
      text-decoration: underline;
    }

    button {
      margin-top: 1rem;
      padding: 0.5rem;
      width: 80%;
      margin: 1rem auto;
      border-radius: 10px;
      background: var(--primary-color);
      transition: filter 0.2s;
      color: #ffffff;
      font-weight: 700;
      &:hover {
        filter: brightness(0.9);
      }
    }
  }

  .footer{
    grid-column: 1/1;
    grid-row: 3/4;
    /* background: yellow; */
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    img { 
      position: absolute;
      bottom: 2rem;
      width: 10rem;
    }
  }
`