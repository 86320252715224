import { Container } from "./styles";
import { Link } from "react-scroll"; 

interface GroupButtonProps {
  title: string;
  group_id: string;
}

export function GroupButton({title, group_id}: GroupButtonProps){

  return(
    <Container>
      <Link 
        to={group_id}
        spy={true} 
        smooth={true}>
        <span>{title}</span>
      </Link>
    </Container>
  );
}