import { TopBar } from "../components/ProductDetails/TopBar";
import { ContentProductDetail } from "../components/ProductDetails/ContentProductDetail";

export default function ProductDetail(){
  return(
    <>
      <TopBar/>
      <ContentProductDetail/>
    </>
  );
}
