import { Container, 
         HeaderTitleSection, 
         HeaderLocationSection, 
         HeaderBadgeSection,
         HeaderContactBadgeSection,
         Informations } 
from "./styles";
import { Divider, Text } from "@chakra-ui/react"; 
import { useEffect, useState } from "react";
import { api } from '../../contexts/UrlContext';
import { empresa } from "../../services/url";

interface EmpresaDados {
  fantasia: string,
  cep: string,
  cidade: string,
  endereco_completo: string,
  endereco_nro: string,
  estado: string,
  complemento: string,
  telefone: string,
  whatsapp: string,  
}

export function HeaderInformations(){

  const [empresaDados, setEmpresa] = useState<EmpresaDados>();  

  
  useEffect(() => {
    async function getEmpresaDados() { 

      let options = {
        headers: {
          cnpj: empresa.cnpj
        },
      };
      
            if(empresa.cnpj){
      await api.get<EmpresaDados>('estabelecimento/catalogo', options)
               .then((response) => {
                setEmpresa(response.data) 
               })
               .catch((error) => {
                 console.log(error)
               })      
      }
    }
  
    getEmpresaDados()    
  }, [])




  


  const dataEnd  = () =>{  
    let dados = " ";

    
    if(empresaDados?.endereco_completo){
      dados = dados + empresaDados.endereco_completo
    }

    if( empresaDados?.endereco_nro ){
      dados = dados + ", Número: " + empresaDados.endereco_nro
    }

    if( empresaDados?.complemento ){
      dados = dados + ", " + empresaDados.complemento
    }

    if(empresaDados?.cep){
      let newCEP = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/;
      newCEP = Object(empresaDados).cep.replace(newCEP,"$1.$2-$3");
      dados = dados + " - CEP: " + newCEP
    }
    return dados;
  }

  return(
    <Container>
      <HeaderTitleSection>
      <Text fontSize="xl">
        { empresaDados?.fantasia }
      </Text>
      
      <HeaderBadgeSection>
        {/*<Badge variant="solid" colorScheme="green">
          Aberto
        </Badge>
         <Badge variant="solid" colorScheme="red">
          Fechado
        </Badge> */}
        </HeaderBadgeSection>
      </HeaderTitleSection>
      <HeaderLocationSection>
        <Informations>
        {/* <HiLocationMarker /> */}  
          { 'Endereço: ' + dataEnd()     }
        </Informations>

      <HeaderContactBadgeSection>
     
      {/* <HiPhone size={30}/> */}
      {/* <IoLogoWhatsapp size={30} color="#25D366"/> */}
      </HeaderContactBadgeSection>

      </HeaderLocationSection>

      <Divider />
    </Container>
  );
}