import { /*ButtonStyled, */ Container, FormFooter, FormBody, ButtonCep } from "./styles";
import { Text } from "@chakra-ui/react"
import { Input } from "@chakra-ui/react";
import { Stack } from "@chakra-ui/react";
import { Grid } from "@chakra-ui/react";
import { NumberInput /*, NumberInputField*/ } from "@chakra-ui/react";
import InputMask from "react-input-mask";
import { FormEvent, useState } from "react";
import { Endereco } from '../../../types/types';
// import { api } from '../../../services/api';
import axios from 'axios';
import { StyledInput } from "../../Mascaras/Telefone/styles";
import { api } from "../../../contexts/UrlContext";
interface DadosInput {
  nome:       string;
  email:       string;
  senha:       string;
  cpf:         string,
  telefone:    string,
  cep:         string,
  numero:      string,
  logradouro:  string,
  localidade:  string,
  // uf:          string,
  complemento: string,
}

export function FRegister() {

  const [endereco, setEnderecoList] = useState<Endereco>({} as Endereco);
  console.log(endereco)
  const [dados, setDados] = useState<DadosInput>({
    nome:        "",
    email:       "",
    senha:       "",
    cpf:         "",
    telefone:    "",
    cep:         "",
    numero:      "",
    logradouro:  "",
    localidade:  "",
    // uf:          "",
    complemento: "",
  })

	const handleChange = (event: { target: HTMLInputElement; }) => {
    setDados({ 
      ...dados,
      [event.target.name]: event.target.value 
    });

  }

  const [validacoes, setValidacoesList] = useState({
    nome:        false,
    email:       false,
    senha:    false,
    cpf:         false, // Não funciona com o Input
    cep:         false,
    numero:      false,
    logradouro:  false,
    localidade:  false,
    uf:          false,
    complemento: false,
    telefone:    false
  });


  const getAddress = () => {
    axios.get(`https://viacep.com.br/ws/${dados.cep}/json/`).then(response => {
      response.data.cep = response.data.cep.replace('-', '');
      setEnderecoList(response.data)
      setDados({ 
        ...dados,
        ...response.data
      })
    }).catch(err => {
      console.log('Erro ao buscar CEP')
    });
  }

  const handleSave = async (event: FormEvent) => {
    event.preventDefault();
    let error = false;

    const verifyValid = {
      nome:         false,
      email:        false,
      senha:        false,
      cpf:          false,
      cep:          false,
      numero:       false,
      logradouro:   false,
      localidade:   false,
      uf:           false,
      complemento:  false,
      telefone:     false     
    }

    if ( dados.nome.length === 0 || dados.nome.length > 60 ) {
      verifyValid.nome = true;
      error = true;
    }

    if (dados.email.length === 0 || dados.email.length > 120) {
      verifyValid.email = true;
      error = true;
    }

    if ( dados.senha.length === 0 || dados.senha.length > 8) {
      verifyValid.senha = true; 
      error = true;
    }

    if ( String(dados.cpf).length === 0 || (String(dados.cpf).length - 1) > 13) {
      verifyValid.cpf = true;
      error = true;
    }

    if ( String(dados.cep).length === 0 || (String(dados.cep).length - 1 ) > 8) {
      verifyValid.cep = true;
      error = true;
    }

    if ( String(dados.numero).length === 0 || String(dados.numero).length > 14) {
      verifyValid.numero = true;
      error = true;
    }

    if ( dados.logradouro.length === 0 || dados.logradouro.length > 120) {
      verifyValid.logradouro = true;
      error = true;
    }

    if ( dados.localidade.length === 0 || dados.localidade.length > 40) {
      verifyValid.localidade = true;
      error = true;
    }

    // if ( dados.uf.length === 0 || dados.uf.length > 40) {
    //   verifyValid.uf = true;
    //   error = true;
    // }

    if ( dados.complemento.length === 0 || dados.complemento.length > 120) {
      verifyValid.complemento = true;
      error = true;
    }

    if (dados.telefone.length === 0 || dados.telefone.length > 10) {
      verifyValid.telefone = true;
      error = true;
    }

    if (error) {
      console.log('ok')
      setValidacoesList({...validacoes, ...verifyValid});
    }
    else {
      await api.post(`clientes`, {...dados}).then(response => {
        console.log('Usuario Cadastrado com sucesso!')
      }).catch(err => {
        console.log('Erro ao Cadastrar');
        // console.log({...dados});
      });
    }
    // console.log(dados)
  }

  return (
    <Container>
      <Stack spacing={2}>
        <FormBody onSubmit={handleSave}>
          <Text fontSize="2xl" mb="1px">Nome</Text>
          <Input variant="outline" placeholder="Digite seu nome" name="nome" isInvalid={validacoes.nome} onChange={handleChange} />
          
          <Text fontSize="2xl" mb="1px">E-mail</Text>
          <Input variant="outline" placeholder="Digite seu e-mail" name="email" isInvalid={validacoes.email} onChange={handleChange} />
          
          <Text fontSize="2xl" mb="1px">Senha</Text>
          <Input variant="outline" type="password" placeholder="Digite sua senha" name="senha" isInvalid={validacoes.senha} onChange={handleChange} />
         
          <Text fontSize="2xl" mb="1px">CPF</Text>
          <NumberInput isInvalid={validacoes.cpf}>
          <InputMask 
              mask="999.999.999-99"
              onChange={handleChange}
            >
              {() => 
                <StyledInput
                  placeholder="Digite seu CPF" 
                  id="cpf"
                  name="cpf"
                />
              }
            </InputMask>
          </NumberInput> 
          {/* <NumberInput isInvalid={validacoes.cpf}>
            <NumberInputField placeholder="Digite seu CPF" name="cpf" onChange={handleChange}/>
          </NumberInput> */}

          <Text fontSize="2xl" mb="1px">Endereço</Text>
          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
              <Grid templateColumns="repeat(2, 1fr)" >
              <NumberInput isInvalid={validacoes.cep}>
                <InputMask 
                    mask="99999-999"
                    onChange={handleChange}
                  >
                    {() => 
                      <StyledInput
                        placeholder="CEP" 
                        id="cep"
                        name="cep"
                      />
                    }
                  </InputMask>
              </NumberInput>                
                {/* <NumberInput isInvalid={validacoes.cep}>
                  <NumberInputField placeholder="CEP" id="cep" name="cep" onChange={handleChange} />
                </NumberInput> */}
                <ButtonCep onClick={getAddress}><p>Verificar CEP</p></ButtonCep>
              </Grid>
            <Input variant="outline" placeholder="Número" name="numero" id="numero" onChange={handleChange} isInvalid={validacoes.numero} />
          </Grid>
          
          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            <Input variant="outline" placeholder="Logradouro" name="logradouro" id="logradouro" isInvalid={validacoes.logradouro} value={dados.logradouro} onChange={handleChange} />
            <Input variant="outline" placeholder="Cidade" name="localidade" id="localidade" isInvalid={validacoes.localidade} value={dados.localidade} onChange={handleChange} />
            {/* <Input variant="outline" placeholder="Estado" name="uf" id="uf" value={dados.uf} isInvalid={validacoes.uf} onChange={handleChange} /> */}
          </Grid>
          
          <Input variant="outline" placeholder="Complemento" mb="2px" name="complemento" id="complemento" /*isInvalid={validacoes.complemento}*/ onChange={handleChange} />

          <Text fontSize="2xl" mb="1px">Telefone/Celular</Text>
          <NumberInput isInvalid={validacoes.telefone}>
          <InputMask 
              mask="(99) 9999-9999"
              value={dados.telefone}
              onChange={handleChange}
            >
              {() => 
                <StyledInput
                  placeholder="Digite seu número" 
                  id="telefone"
                  name="telefone"
                />
              }
            </InputMask>
          </NumberInput>

          <FormFooter>
            {/* <ButtonStyled type="submit" size="md" colorScheme="#F37021" onClick={handleSave}  >
              Concluir
            </ButtonStyled> */}

            <button type="submit" disabled /* onClick={handleSave} */ >
              Concluir
            </button>
          </FormFooter>

        </FormBody>
      </Stack>
    </Container>
  );
}
