import styled from 'styled-components';

export const Container = styled.header`
  width: 100%;
  max-width: 750px;
  background: white;
  min-height: 5rem;
  max-height: 10rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat';
  font-weight: 500;
  padding-left: 1rem;
`;

export const HeaderTitleSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
  margin-top: 0.5rem;
`;

export const HeaderLocationSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Informations = styled.div`
  display: flex;
`;

export const HeaderBadgeSection = styled.div`
  margin-right: 1rem;
`;

export const HeaderContactBadgeSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1rem;
`;
