import { Container } from './styles';
// import { BiMenu } from 'react-icons/bi';
// import { IconButton } from '@chakra-ui/react';
// import { useDrawer } from '../../hooks/useCart';
import { /* Sticky,*/ StickyContainer } from 'react-sticky';

export function TopBar() {
  // const { handleOpenDrawer } = useDrawer();
  return (
    <StickyContainer>
      <Container>
        {/* <IconButton
          colorScheme="White"
          aria-label="Call Sage"
          fontSize="20px"
          onClick={handleOpenDrawer}
          icon={<BiMenu />}
        /> */}
      </Container>
    </StickyContainer>
  );
}
