import { TopBar } from "../components/NavBar";

export const Pagina403 = () => {
  
  return (
    <>
      <TopBar />
      <h1>Conteúdo bloqueado!</h1>
    </>
  )
}