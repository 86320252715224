import axios from "axios";
import { createContext, ReactNode, useState } from "react";
// import { apiUrl } from "../services/apiUrl";
import { empresa } from "../services/url";

interface Url {
  id: string,
  subdominio: string,
  sistemadescricao:  string,
  situacaodescricao: string,
  nome: string,
  cnpj: string,
}
interface UrlProviderProps {
  children: ReactNode;
}

export let UrlContext = createContext<Url>({

  id: "",
  subdominio: window.location.pathname.split("/")[1],
  sistemadescricao:  "",
  situacaodescricao: "",
  nome: "",
  cnpj: "",

});

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

let options = {
	headers: {
	  cnpj: empresa.cnpj
	},
};

export {options}

export const UrlProvider = ({ children }: UrlProviderProps) => {
  const [url] = useState<Url>({
    id: "",
    subdominio: window.location.pathname.split("/")[1],
    sistemadescricao:  "",
    situacaodescricao: "",
    nome: "",
    cnpj: "",    
  });

  return <UrlContext.Provider value={url}>{children}</UrlContext.Provider>;
};
