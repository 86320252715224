import styled from 'styled-components';

interface ContainerProps {
  show: boolean;
}

export const Container = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;

  display: ${({ show }: ContainerProps) => (show === true ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;

  background-color: #00000020;

  z-index: 1;
`;
