// import axios from "axios";
import { apiUrl } from "./apiUrl";
interface DadosEmpresa {
  id: string;
  subdominio: string;
  sistemadescricao: string;
  situacaodescricao: string;
  nome: string;
  cnpj: string;
}
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
var empresa = <DadosEmpresa>{};

const getEmpresa = async () => {
  const empr = window.location.pathname.split("/")[1];

  const params = {
    subdominio: empr,
    situacao: "Ativo",
    sistema: "Pedido Web",
  };

  await apiUrl
    .get("clientesistema/", { params: params })
    .then((resp) => {
      let respData = JSON.parse(JSON.stringify(resp.data[0]));

      console.log();

      empresa.id = respData.id;
      empresa.subdominio = empr;
      empresa.sistemadescricao = respData.sistema.descricao;
      empresa.situacaodescricao = respData.sistema.status;
      empresa.nome = respData.cliente.nome;
      empresa.cnpj = respData.cliente.numero;
    })
    .catch((err) => {
      console.log("Erro: " + err);
    });
};
getEmpresa();

export { empresa };
