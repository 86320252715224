import { useHistory } from "react-router-dom";
import { Container, 
         ContainerImg, 
         ContainerBody, 
         ContainerTitle, 
         ContainerDescription, 
         ContainerPrice } 
from "./style";
import { Text, Divider } from "@chakra-ui/react"
import { formatPrice } from '../../utils/formatNumber';
import { empresa }  from "../../services/url";

interface CardProdutoProps{
  nome: string;
  imagem: string;
  id: number;
  descricao: string;
  valor: number;
  guiid: string;
}


export function CardProduto({nome, imagem, id, descricao, valor, guiid}: CardProdutoProps){

  let history = useHistory();

  const redirect = () => {
    history.push(`${empresa.subdominio}/produto/${id}`)
  }
  
  return(
    <>
    <Container key={ id } onClick={redirect}>
      
      <ContainerImg>
        <img src={imagem} alt="" />
      </ContainerImg>
      <ContainerBody>
        <ContainerTitle>

        <Text fontSize="sm">
          <b>{nome}</b>
        </Text>

          </ContainerTitle>
        <ContainerDescription>

        <Text fontSize="xs">
        {descricao}        
        </Text>
        
        </ContainerDescription>

        <ContainerPrice>
        <Text fontSize="sm">
        <b>{formatPrice(valor)}</b>
        </Text>
        </ContainerPrice>
      </ContainerBody>

    </Container>  
    <Divider />
    </>
  );

}