import styled from 'styled-components';

export const Container = styled.div`
  background: #57585a;
  color: white;
  padding: 0.5rem;
  display: inline-block;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 10px;
  cursor: pointer;
  font-family: 'Montserrat';
  font-size: 0.8rem;
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.95);
  }

  & + div {
    margin-left: 0.5rem;
  }
`;
