import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  font-family: 'Montserrat';
`;

export const ContainerTitle = styled.div`
  background: var(--background-color);
  padding: 1rem 1rem;
  font-weight: bold;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
`;
