import { Container, ContainerTitleSection } from './styles';
import { IoIosArrowBack } from 'react-icons/io'; 
import { IconButton } from '@chakra-ui/react';
import { useHistory } from "react-router-dom";
import  {empresa}  from '../../../services/url';

export function TopBar(){
  let history = useHistory();

  const redirect = () => {
    history.push(`/${empresa?.subdominio}`)
  }
  
  return(
      <Container>
        
        <IconButton
          colorScheme="White"
          aria-label="Call Sage"
          fontSize="20px"
          position="absolute"
          onClick={redirect}
          icon={<IoIosArrowBack />}
        />
        <ContainerTitleSection>
          Detalhe do item
        </ContainerTitleSection>
      </Container>
  );  
}