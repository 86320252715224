import { useEffect, useState } from 'react';
import { CardProduto } from '../CardProduto';
import { SectionTitle } from '../SectionTitle';
import { Container, ContainerGroup, ContainerSearch } from './styles';
// import { api } from '../../services/api';
import { ScrollToTop } from '../ButtonTop';
import { GroupButton } from '../GroupButton';
import ScrollContainer from 'react-indiana-drag-scroll';
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { FiSearch } from 'react-icons/fi';
// import { Produto } from '../../types/types';
import { api } from '../../contexts/UrlContext';
import { empresa } from '../../services/url';
import { Loading } from '../Loading';

interface ProdLists {
  id: number;
  descricao: string;
  data_criacao: string;
  data_modificacao: string;
  produtos: {
    id: number;
    id_grupo: number;
    id_integracao: number;
    nome: string;
    descricao: Buffer;
    preco: number;
    foto: Buffer;
    ativo: boolean;
    flag_observacao: boolean;
    data_criacao: string;
    data_modificacao: string;
  }[];
}

interface InitialState {
  id: number;
  descricao: string;
  data_criacao: string;
  data_modificacao: string;
  produtos: {
    id: number;
    id_grupo: number;
    id_integracao: number;
    nome: string;
    descricao: Buffer;
    preco: number;
    foto: Buffer;
    ativo: boolean;
    flag_observacao: boolean;
    data_criacao: string;
    data_modificacao: string;
  }[];
}

export function Content() {
  let options = {
    headers: {
      cnpj: empresa.cnpj,
    },
  };

  const [prodLists, setProdLists] = useState<ProdLists[]>([]);
  const [search, setSearch] = useState<ProdLists[]>([]);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    async function getProdLists() {
      if (options.headers.cnpj !== '' || options.headers.cnpj !== undefined) {
        const response = await api.get<ProdLists[]>('grupos/produtos', options);
        setProdLists(response.data);
      }
    }

    async function getSearchProdLists() {
      if (options.headers.cnpj !== '' || options.headers.cnpj !== undefined) {
        const response2 = await api.get<ProdLists[]>(
          'grupos/produtos',
          options,
        );
        setSearch(response2.data);
      }
    }

    getProdLists();
    getSearchProdLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function getInitialProdLists() {
      if (options.headers.cnpj !== '') {
        const response3 = await api.get<InitialState[]>(
          'grupos/produtos',
          options,
        );
        setSearch(response3.data);
      }
    }
    getInitialProdLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prodLists]);

  function tratarBlobDescricao(descricao: Buffer) {
    return Buffer.from(descricao).toString('utf8');
  }

  function tratarBlobImagem(descricao: Buffer) {
    const base64 = Buffer.from(descricao).toString('base64');
    return atob(base64);
  }

  function handleSearchChange(value: string) {
    if (value.length > 0) {
      filterItems(value);
    } else {
      setShowLoading(true);
      resetItems();
      setShowLoading(false);
    }
  }

  const resetItems = async () => {
    if (options.headers.cnpj !== '' || options.headers.cnpj !== undefined) {
      const response = await api.get<ProdLists[]>('grupos/produtos', options);
      setProdLists(response.data);
    }
  };

  const filterItems = (value: string) => {
    const newResponse = search
      .filter(function (el) {
        const response = (el.produtos = el.produtos.filter((x) =>
          x.nome.toLowerCase().includes(value.toLowerCase()),
        ));
        return response;
      })
      .filter(({ produtos }) => produtos.length);

    setProdLists(newResponse);
  };

  return (
    <Container>
      <Loading show={showLoading} />
      <ContainerSearch>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<FiSearch color="gray.300" />}
          />
          <Input
            variant="filled"
            placeholder="Pesquise aqui..."
            onChange={(e) => handleSearchChange(e.target.value)}
          />
        </InputGroup>
      </ContainerSearch>

      <ScrollContainer vertical={false} horizontal={true}>
        <ContainerGroup>
          {prodLists.length > 0 &&
            prodLists.map((grupo) =>
              grupo.produtos.length > 0 ? (
                <GroupButton
                  title={grupo.descricao || ''}
                  group_id={grupo.id?.toString() || ''}
                  key={grupo.descricao}
                />
              ) : (
                ''
              ),
            )}
        </ContainerGroup>
      </ScrollContainer>

      {Object.entries(prodLists).map((grupo) => {
        return grupo[1].produtos.length > 0 ? (
          <SectionTitle
            title={grupo[1].descricao || ''}
            sectionGroupId={String(grupo[1].id)}
          >
            {grupo[1].produtos?.map((product) => {
              return (
                <CardProduto
                  descricao={tratarBlobDescricao(Object(product).descricao)}
                  imagem={tratarBlobImagem(Object(product).foto)}
                  nome={Object(product).nome}
                  valor={Object(product).preco}
                  id={Object(product).id}
                  guiid=""
                />
              );
            })}
          </SectionTitle>
        ) : (
          ''
        );
      })}
      <ScrollToTop />
    </Container>
  );
}
