import { Button } from '@chakra-ui/button';
import { Textarea } from '@chakra-ui/react';
import styled from 'styled-components';

export const Container = styled.div`  
  max-width: 750px;
  width: 100%;
  background: rgba(255, 255, 255, 0);
  margin: -2rem auto;
  position: relative;
  display: flex;
  flex-direction: column;
  @media(max-width: 840px) {
    max-width: 90%;
  }
`;

export const AddProduct = styled(Button)`
  max-width: 100%;
  margin: 0 25% 0 25%;
  @media(max-width: 1000px) {
      max-width: 100%;
      margin: 0;
  }  
`;

export const Description = styled(Textarea)`
  background: white;
  //display: flex;
  //height: auto;
  //width: 100%;
  //margin: 0 auto;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  cursor: default;
  font-family: "Montserrat";
  box-shadow: 0 1px 3px 1px var(--shadow-color);
  margin-bottom: 1rem;
`;



