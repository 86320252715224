import styled from 'styled-components';

export const Container = styled.div`
  padding: 0.7rem 1rem;
  background: #57585a;
  height: 4rem;
  width: 100%;
  position: sticky;
  display: inline-block;
`;

export const HeaderInformations = styled.div`
  height: 2rem;
  background: black;
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
`;
