import styled from "styled-components";

export const StyledInput = styled.input`
  background: red;
  margin-bottom: 1rem;
  border: none;
  border-top-color: currentcolor;
  border-right-color: currentcolor;
  border-bottom-color: currentcolor;
  border-left-color: currentcolor; 
`;