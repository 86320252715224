import styled from 'styled-components';

export const Container = styled.div`
  padding: 0.7rem 1rem;
  background: #57585a;
  height: 4rem;
  width: 100%;
  position: sticky;
  padding-top: 2rem;
  padding-bottom: 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: static;
`;

export const ContainerTitleSection = styled.div`
  font-size: 1rem;
  color: white;
  font-weight: bold;
  width: 100%;
  text-align: center;
  position: static;
`;
