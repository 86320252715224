import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton
} from "@chakra-ui/react";
import { useDrawer } from '../../hooks/useCart';

export function DrawerApplication() {
  const { isDrawerOpen, handleCloseDrawer } = useDrawer();

  if (false) {
    return (
      <Drawer
        isOpen={isDrawerOpen}
        placement="left"
        onClose={handleCloseDrawer}
      >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Olá, Usuário</DrawerHeader>
            <DrawerBody>

            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    );
  }

  else {
    return (
      <Drawer
        isOpen={isDrawerOpen}
        placement="left"
        onClose={handleCloseDrawer} >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Faça login para continuar!</DrawerHeader>
            <DrawerBody>

            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    );
  }
}