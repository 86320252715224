import { Container } from './styles';
import ReactLoading from 'react-loading';

interface LoadingProps {
  show: boolean;
}

export function Loading({ show }: LoadingProps) {
  return (
    <Container show={show}>
      <ReactLoading
        type="spinningBubbles"
        color="#57585A"
        width={150}
        height={200}
      />
    </Container>
  );
}
