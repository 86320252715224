import { TopBar } from "../components/NavBar";
// import { empresa } from "../services/url"

export const Pagina404 = () => {
  
  return (
    <>
      <TopBar />
      <h1>Página não encontrada!</h1>
    </>
  )
}