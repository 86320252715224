import {createGlobalStyle} from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root{
    --primary-color: #F36E21; 
    --background-color: #F9F9F9;
    --shadow-color: #888888;
  }

  body{
    background-color: var(--background-color);
    font-family: 'Montserrat', sans-serif;
  }

  *{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
  }
`;

export default GlobalStyle;