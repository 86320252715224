import { TopBar } from "../components/NavBar";
// import { HeaderInformations } from "../components/HeaderInformations";
import { FRegister } from "../components/Form/Register";


export default function Register(){
  return(
    <>
      <TopBar />
      {/* <HeaderInformations /> */}
      <FRegister />
    </>
  );
}
