import { ReactNode } from "react";
import { Container, ContainerTitle } from "./style";

interface SectionTitleProps{
  title: string;
  children: ReactNode;
  sectionGroupId: string;
};

export function SectionTitle({title, children, sectionGroupId}: SectionTitleProps){
  return(
    <Container id={sectionGroupId}>
          <ContainerTitle>
              {title}
          </ContainerTitle>
      {children}
    </Container>
  );


}