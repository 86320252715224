import { useEffect, useState } from 'react';
import { CardProductDetail } from './CardProductDetail';
// import { ItemCounter } from "./ItemCounter";
// import { Price } from "./Price";
import { Container /*, AddProduct, Description */ } from './styles';
// import { useDrawer } from '../../../hooks/useCart';
import { /* useHistory ,*/ useParams } from 'react-router-dom';
import { Produto } from '../../../types/types';
import { Text } from '@chakra-ui/layout';
// import { Textarea } from '@chakra-ui/textarea';
import { api } from '../../../contexts/UrlContext';
import { empresa } from '../../../services/url';

export function ContentProductDetail() {
  // let history = useHistory();

  const redirect = () => {
    // history.push(`/${empresa.SUBDOMINIO}`)
  };
  // const {avaibleProducts} = useDrawer();
  const { id } = useParams<{ id: string }>();
  const [product, setProduct] = useState<Produto>();
  // const [amount, setAmount] = useState<number>(1);

  // const sum = () => {
  //   const newAmount = amount + 1;
  //   setAmount(newAmount);
  // }

  // const sub = () => {
  //   const newAmount = amount - 1;

  //   if(newAmount >= 1){
  //     setAmount(newAmount);
  //   }
  // }

  useEffect(() => {
    async function teste() {
      let options = {
        headers: {
          cnpj: empresa.cnpj,
        },
      };

      await api
        .get<Produto>(`produto/${id}`, options)
        .then((response) => {
          setProduct(response.data);
        })
        .catch((err) => {
          redirect();
        });
    }
    teste();
  }, [id]);

  function tratarBlobDescricao(descricao: Buffer) {
    return Buffer.from(descricao).toString('utf8');
  }

  function tratarBlobImagem(imagem: Buffer) {
    const base64 = Buffer.from(imagem).toString('base64');
    return atob(base64);
  }

  return (
    <Container>
      {product && (
        <CardProductDetail
          descricao={tratarBlobDescricao(product.descricao)}
          imagem={tratarBlobImagem(product.foto)}
          nome={product.nome}
          valor={product.preco}
          id={product.id}
        />
      )}
      {/* <Grid templateColumns="1fr 3fr" 
            gap={10}
            marginTop="1rem"
            marginBottom="1rem"
      >
        <ItemCounter amount={amount} sub={sub} sum={sum}/>     
        {product && <Price valor={product.PRECO} quantidade={amount}/>}
      </Grid>  */}
      <Text fontSize="1.5rem" fontWeight="bold" color="#57585A">
        Descrição
      </Text>
      {/* <Textarea //placeholder="Escreva aqui..." 
                isReadOnly
                boxShadow="0 1.6px 3px 1px var(--shadow-color)"
                marginTop="0.5rem"
                marginBottom="1rem"
                border="none"
                focusBorderColor="rgba(0 0 0)"/>  */}

      <Text>{product && tratarBlobDescricao(product.descricao)}</Text>
      {/* <AddProduct colorScheme="orange" 
                  size="lg" 
                  align="center"
                  _focus={{
                    boxShadow: "none",
                  }}>
        ADICONAR AO PEDIDO
      </AddProduct> */}
    </Container>
  );
}
