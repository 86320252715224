// import { emit } from 'node-process';
import styled from 'styled-components';

export const ScroolToTop = styled.div`
button{
    background-color: #F36E21;
    color: white;

    &:hover{
        background-color: #F9A716;
    }

position: fixed;
bottom: 0;
right:  0;
margin: 0 1rem 1rem 0
}
`
