import React, { ReactElement,  useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ProductDetail from "../pages/ProductDetail";
import Index from "../pages/index";
import Register from "../pages/Register";
import GlobalStyle from "../styles/GlobalStyles";
import { ChakraProvider } from "@chakra-ui/react"
import { DrawerApplication } from '../components/Drawer/';
import {MainProvider} from '../hooks/useCart';
import { Login } from "../components/Form/Login";
import { Pagina404 } from "../pages/Pagina404";
import { Pagina403 } from "../pages/Pagina403";
import {  UrlProvider } from "../contexts/UrlContext";
import { apiUrl } from "../services/apiUrl";
interface ClienteSistema {
  id: string,
  sistema: {
    descricao: string
  },
  situacao:{
    descricao: string
  },
  cliente:{
    nome: string,
    numero: string
  }
}
  
let empresa = {
  ID: '',
  SUBDOMINIO: '',
  CNPJ: '',
}

const Routes = () => {
  const [Rotas, setRotas] = useState<ReactElement[]>([]);

  useEffect(() => {
    async function getEmpresa () {

      const subdo = window.location.pathname.split('/')[1];

      await apiUrl.get<ClienteSistema>('clientesistema/', { params: { 'subdominio': subdo, 'situacao': 'Ativo' }})
      .then(response => {     
        let ativo = 0;

        if (Object(response.data)[0].sistema.status === 'Ativo') {
           ativo = 1;
           empresa.SUBDOMINIO = subdo;        
           empresa.CNPJ = Object(response.data)[0].cliente.numero;
        }

        if(ativo === 0){
          setRotas([
            <Route key="403" > 
              <Pagina403 /> 
            </Route>      
          ])
        } else {
          setRotas([
            <Route key="1" exact path={`/${empresa.SUBDOMINIO}/`}            component={Index} />,
            <Route key="2" exact path={`/${empresa.SUBDOMINIO}/login`}       component={Login} />,
            <Route key="3" exact path={`/${empresa.SUBDOMINIO}/cadastro`}    component={Register} />,
            <Route key="4" exact path={`/${empresa.SUBDOMINIO}/produto/:id`} component={ProductDetail} />       
          ])
        };
      }).catch(error => {
        setRotas([
          <Route key="404" > 
            <Pagina404 /> 
          </Route>      
        ])
      })        
    }
    getEmpresa();
  }, []);

  return (
    <UrlProvider>
      <Router>
        <ChakraProvider>
          <MainProvider>
          <Switch> 
            {Rotas}
          </Switch>
          <DrawerApplication />
          <GlobalStyle />
          </MainProvider>
        </ChakraProvider>
      </Router> 
    </UrlProvider> 
  );
};  

export default Routes;
