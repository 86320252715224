import { useDisclosure } from '@chakra-ui/react';
import { createContext, useEffect, useState, ReactNode, useContext } from 'react';
import React from 'react';
import { Produto } from '../types/types';
// import {empresa} from '../services/url';
import { api, options } from '../contexts/UrlContext';

  interface MainContextData {
    isDrawerOpen: boolean;
    avaibleProducts: Produto[];
    cartProducts: Produto[];
    handleCloseDrawer: () => void;
    handleOpenDrawer: () => void;
    searchAvaibleProducts: () => void;
  }

const MainContext = createContext<MainContextData>({} as MainContextData);


interface MainProviderProps{
  children: ReactNode;
}

// interface GroupItems{
//   products: Produto[];
// }

// interface CartItems {
//   [key: string]: Produto;
// }

export function MainProvider({children}: MainProviderProps){

  const {isOpen, onClose, onOpen} = useDisclosure();
  const [produtosDisponiveis, setProdutosDisponiveis] = useState<Produto[]>([]);

  async function searchAvaibleProducts (){
    if(options.headers.cnpj){
      const reponse = await api.get<Produto[]>('produto/1', options);
      const products = [...reponse.data];
      setProdutosDisponiveis(products);
      console.log(products)
    }    
  };

  useEffect(() => {
    searchAvaibleProducts();

  }, []);

  function openDrawer(){
    onOpen();
   // console.log(isOpen);
  }

  function closeDrawer(){
    onClose();
   // console.log(isOpen);
  }

  return(
    <MainContext.Provider value={{isDrawerOpen: isOpen, handleCloseDrawer: closeDrawer, handleOpenDrawer: openDrawer, avaibleProducts: produtosDisponiveis, cartProducts: [], searchAvaibleProducts: searchAvaibleProducts}}>
      {children}
    </MainContext.Provider>
  );
}

export function useDrawer(){
  const context = useContext(MainContext);

  return context;
}