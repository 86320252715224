import styled from 'styled-components';

export const Container = styled.div`
  max-width: 750px;
  width: 100%;
  background: #ffffff;
  /* height: 100vh; */
  margin: 1rem auto;
`;

export const ContainerGroup = styled.div`
  max-width: 750px;
  width: 100vw;
  background: #ffffff;
  display: flex;
  margin: 0.75rem 0.4rem;
`;

export const ContainerSearch = styled.div`
  padding: 0.4rem;
  margin-top: 1rem;
`;
