import styled from 'styled-components';

export const Container = styled.div`
  background: white;
  display: flex;
  height: auto;
  width: 100%;
  margin: 0 auto;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  cursor: default;
  font-family: 'Montserrat';

  box-shadow: 0 1px 3px 1px var(--shadow-color);
`;

export const ContainerImg = styled.div`
  width: 5rem;
  height: 5rem;
  margin: 0.5rem 0.5rem;
  img {
    border-radius: 5px;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

export const ContainerBody = styled.div`
  width: 100%;
  height: 100%;
  margin: 0.5rem 0.5rem;
  display: flex;
  flex-direction: column;
`;

export const ContainerTitle = styled.div`
  display: flex;
  align-items: space-between;
  justify-content: space-between;
  width: 100%;
`;

export const ContainerDescription = styled.div`
  width: 100%;
  font-size: 0.75rem;
  color: rgb(48, 51, 60);
`;

export const ContainerPrice = styled.div`
  height: 20%;
`;
