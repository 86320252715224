import React, { useEffect, useState } from "react";
import { BiArrowToTop } from 'react-icons/bi';
import { IconButton } from '@chakra-ui/react';
import { ScroolToTop } from './styles';


export function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);

 
    return (
    <>
      {isVisible && 
        <ScroolToTop onClick={scrollToTop}>
         <IconButton          
          aria-label="Call Sage"
          fontSize="20px"      
          onClick={scrollToTop}  
          icon={<BiArrowToTop />}
        />
        </ScroolToTop> }
    </>
  );
}
